

@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';

.bg-latam {
    background-color: #1b0088!important;
}

.bg-latam2 {
    background-color: #150165!important
}

.white-icon {
    color: white;
}

html {
    position: relative;
    min-height: 100%;
}

html,
body {
    height: 100%;
}

body>.container {
    padding: 60px 15px 0;
}

.panel {
    min-height: 100%;
}

.panel.izq {
    border-top-left-radius: 70px;
    border-bottom-left-radius: 70px;
}

.panel.der {
    border-top-right-radius: 70px;
    border-bottom-right-radius: 70px;
}

.azafata {
    width: 150%;
    position: relative;
    right: 25%;
    top: 5%;
}

.text-black {
    color: black!important;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer!important;
}

.upload-btn-wrapper input[type=file] {
    position: absolute;
    right: 0%;
    top: 0;
    opacity: 0;
    cursor: pointer!important;
}

.modal-free {
    max-width: 55.333333%!important;
    min-height: 75%!important;
}

@media (min-width: 376px) and (max-width: 575px) {
    body {
        background-size: 300% 100%;
    }
}

@media (min-width: 576px) and (max-width: 775px) {
    body {
        background-size: 300% 100%;
    }
}

body {
    margin-bottom: 60px;
    /*background: url("assets/resources/images/2-compressed.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;*/
    background-size: 100% 100%;
}

.pointer {
    cursor: pointer!important;
}

.text-green {
    color: green;
}

.text-red {
    color: red;
}

.form-control {
    font-weight: 500!important;
}

.personalizado label {
    width: 100%;
    max-width: 100%;
}

tbody .border-b-4 {
    border-top: 4px solid #dee2e6!important;
}

.personalizado {
    width: 37em!important;
}

.personalizado input {
    margin: 0!important;
}

.procesos {
    display: table-cell;
    flex-wrap: wrap;
}

.usuarios table tbody td {
    border-top: 3px solid #dee2e6
}

table {
    font-weight: 800;
}

.popover-body {
    font-weight: 600;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: black!important;
}

.uneditable-input:focus {
    border-color: none!important;
    box-shadow: none!important;
    outline: 0 none!important;
}

.font {
    color: #000;
    font-size: 1em;
    font-weight: bold;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated.delay-5s {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

.animated.fast {
    -webkit-animation-duration: 3000ms;
    animation-duration: 3000ms;
}

.animated.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
}

.animated.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

.animated.slower {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
}

.tamano {
    width: 60% !important;
}

.botones {
    height: 35px !important;
}



.procesarPro {
    margin-top: 30px !important;
}
@media (max-width: 676px) {
    .procesarPro {
margin: 0 auto !important;
text-align: center;
    }
}

.carga {
    padding: 6px 33px;
}

#themecolors a {
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.w150 {
    width: 150px;
}

.w70 {
    width: 70px;
}

.img-50 {
    width: 50px;
}

.oculto {
    display: none;
}

.fondo-negro {
    background-color: rgba( 0, 0, 0, 0.4);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
